import '../../styles/pdf.css'
import React, {forwardRef, ReactNode, useEffect, useRef,} from "react";
import {useAppSelector} from "../../hooks";
import {convertToRoman, formatInternationalPhoneNumber, formatPrice, getNoun, getQuarter} from "../helpers";
import {useSearchParams} from "react-router-dom";
import {defaultIcon} from "../map/mapData";

interface Props {
    ref: ReactNode,
    data: any,
    status: string,
}

const Pdf = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {data, status} = props;
    // const {data} = useAppSelector(state => state.dataSlice);
    const {dataStatic} = useAppSelector(state => state.dataStatic);
    const {dataReadyIcons} = useAppSelector(state => state.dataReadyIcons);

    const [searchParams, setSearchParams] = useSearchParams();
    const builderHouse = searchParams.get('house');
    const builderHC = searchParams.get('builder');

    const house = data.housing_complex || data.apartment.housing_complex;
    const agent = data.agent;
    const images = house.images;

    const phone = agent.phone !== null ? formatInternationalPhoneNumber(agent.phone, 'RU') : null;
    const year = house.deadline ? new Date(house.deadline).getFullYear() : null;
    const featuresItems = house.advantages ? house.advantages.replaceAll(/(<\/*ul>|\t|<\/li>)/g, '').split('<li>') : null;

    let estate:any;
    const quarterDate = house.deadline && getQuarter(house.deadline);

    if(builderHouse){
        estate = data.apartment.estate ? data.apartment.estate : null;
    }

    const locationBlock = useRef<HTMLDivElement>(null);
    const locationRef = useRef<HTMLParagraphElement>(null);
    const aboutLocationParagraph = useRef<HTMLParagraphElement>(null);
    const refFirstPar = useRef<HTMLParagraphElement>(null);

    let wrappeedDescription:any;
    if(house.description && house.description.length > 550){
        wrappeedDescription = house.description.split(/\r?\n/);
    }

    let filterText:any;
    let firstText:any;
    let firstTextMixed:any;
    let aboutLocationText:any;
    let endingText:any;
    let symbolNum:any;
    let checkMixed = house.advantages && house.description;

    if(wrappeedDescription){
        filterText = wrappeedDescription.filter((el:any) => el !== '' && el.length > 50);

        firstText = checkWhiteSpaceAll(0, 550, firstText);

        if(checkMixed){
            firstTextMixed = checkWhiteSpaceAll(0, 250, firstTextMixed);
        } else {
            firstText = checkWhiteSpaceAll(0, 550, firstText);
        }

        if(checkMixed){
            aboutLocationText = checkWhiteSpaceAll(symbolNum,1500, aboutLocationText);
        } else {
            aboutLocationText = checkWhiteSpaceAll(symbolNum,1800, aboutLocationText);
        }
        endingText = checkWhiteSpaceAll(symbolNum,3300, endingText);
    }

    function checkWhiteSpaceAll(start:number, end: number, variable:any) {
        const string = filterText.join('');
        for(let i=end; i < string.length; i++){
            let check: any;
            check = endsWithAny(['.'],filterText.join('').slice(0,i));

            if(check){
                variable = filterText.join('').slice(start,i)
                symbolNum = i;
                return variable;
            }
        }
    }

    function endsWithAny(suffixes:any, string:string) {
        return suffixes.some(function (suffix:any) {
            return string.endsWith(suffix);
        });
    }

    function countPagePdf(){
        const pages = (document.querySelector('.pdf-container') as HTMLElement);
        if(pages){
            for (let i=0; i < pages.children.length; i++){
                const currentPageNodes = pages.children[i].children;
                const num = currentPageNodes[currentPageNodes.length - 1];
                if(num.className === 'num'){
                    num.innerHTML = (1 + i).toString();
                }
            }
        }
    }

    useEffect(() => {
        countPagePdf();
    }, [ref]);

    return (
        <div ref={ref} className={status ? 'pdf-container-loading' : 'pdf-container'}>
            <div className="item">
                {
                    images[0] ? <div className="main-photo"
                                     style={{
                                         backgroundImage: `url(${images[0] ? images[0].links.original : null})`,
                                         backgroundSize: "cover",
                                         backgroundRepeat: "no-repeat",
                                         backgroundPosition: "center center"
                                     }}>
                        </div>
                        : null
                }
                <div className="main-desc px-16 mb-12 w-full">
                    {
                        house.name ?
                            <h3 className="main-desc__title mb-[20px] text-[#151515] w-full">
                                {house.name}
                            </h3>
                            : null
                    }
                    {
                        house.class ?
                            <div className="main-desc__class mb-4">
                                <p className="main-desc__class-text main text-[#151515] leading-[26px]">
                                    {house.class}
                                </p>
                                <p className="main-desc__class-desc desc text-[#151515] leading-[26px]">
                                    Класс ЖК
                                </p>
                            </div> : null
                    }
                    {
                        house.estate_types[0] && house.estate_types[0].name ?
                            <div className="main-desc__class mb-4">
                                <p className="main-desc__class-text main text-[#151515] leading-[26px]">
                                    {house.estate_types[0].name}
                                </p>
                                <p className="main-desc__class-desc desc text-[#151515] leading-[26px]">
                                    Тип дома
                                </p>
                            </div> : null
                    }
                    {
                        house.deadline ?
                            <div className="main-desc__deadline mb-4">
                                <p className="main-desc__deadline-text main text-[#151515] leading-[26px]">
                                    {convertToRoman(quarterDate)} кв. {year} г.
                                </p>
                                <p className="main-desc__deadline-desc desc text-[#151515] leading-[26px]">
                                    Срок сдачи
                                </p>
                            </div> : null
                    }
                    {
                        house.district ?
                            <div className="main-desc__district mb-4">
                                <p className="main-desc__district-text main">
                                    {house.district.name}
                                </p>
                                <p className="main-desc__district-desc desc ">
                                    Район
                                </p>
                            </div> : null
                    }
                </div>
            </div>
            {
                !builderHouse ?
                    null
                    :
                    <div className="item-flat">
                        <div className="item-head">
                            {
                                data.apartment.total_area ?
                                    <h3 className="item-head__title">
                                        Квартира {data.apartment.total_area} м²
                                    </h3> :
                                    <h3 className="item-head__title">
                                        Квартира
                                    </h3>
                            }
                            <div className="head-contacts">
                                <p className="head-contacts__agent mr-6">
                                    {agent.last_name ? agent.last_name : null} <br/>
                                    {agent.first_name ? agent.first_name : null}
                                </p>
                                <div className="head-contacits__group">
                                    <p className="head-contacts__tel">
                                        {phone ? phone : null}
                                    </p>
                                    <p className="head-contacts__mail">
                                        {agent.email ? agent.email : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item-content">
                            <div className="item-pictures">
                                {
                                    data.apartment.flat_plan_image ?
                                        <div className="schema-flat" style={{
                                            backgroundImage: `url(${data.apartment.flat_plan_image.links.preview})`,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center center",
                                            height: "100%"
                                        }}>
                                        </div> : null
                                }
                                {
                                    data.apartment.floor_plan_image ?
                                        <div className="item-pictures-sub">
                                            <div className="schema-floor"
                                                 style={{
                                                     backgroundImage: `url(${data.apartment.floor_plan_image.links.preview})`,
                                                     backgroundSize: "cover",
                                                     backgroundRepeat: "no-repeat",
                                                     backgroundPosition: "center center",
                                                 }}>
                                            </div>
                                        </div> : null
                                }
                            </div>
                            <div className="item-prices">
                                <div className="item-prices__total">
                                    {
                                        data.apartment.price ?
                                            <div className="item-prices__main">
                                            <span className="item-prices__total-price">
                                                {data.apartment.price ? formatPrice(data.apartment.price) : null}
                                            </span>
                                                <span className="item-prices__total-desc font-normal text-[#4D4D4D]">
                                                Цена
                                            </span>
                                            </div> : null
                                    }
                                    {
                                        data.apartment.price_m2 ?
                                            <div className="item-prices__sub">
                                            <span className="item-prices__sub-price">
                                               {data.apartment.price_m2 ? formatPrice(data.apartment.price_m2) : null}
                                            </span>
                                                <span className="item-prices__sub-desc">
                                                т. руб./м²
                                            </span>
                                            </div> : null
                                    }
                                </div>
                                <div className="item-params">
                                    <ul className="params-list first">
                                        {
                                            data.apartment.number != null && data.apartment.number !== '-' ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment.number}
                                                    </span>
                                                    <span className="params-list__item-desc">Номер квартиры</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.max_floor || data.apartment.floor ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment?.max_floor > 0 ? `${data.apartment?.floor} из ${data.apartment?.max_floor}` : data.apartment.floor}
                                                    </span>
                                                    <span className="params-list__item-desc">Этаж</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.entrance_number ?
                                                <li className="params-list__item">
                                                    <span
                                                        className="params-list__item-text">{data.apartment.entrance_number.replace('Секция', '')}</span>
                                                    <span className="params-list__item-desc">Секция</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.total_area ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment.total_area} м<sup>2</sup>
                                                    </span>
                                                    <span className="params-list__item-desc">Площадь общая</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.living_area ?
                                                <li className="params-list__item">
                                                    <span
                                                        className="params-list__item-text">{data.apartment.living_area} м<sup>2</sup></span>
                                                    <span className="params-list__item-desc">Площадь жилая</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            data.apartment.kitchen_area ?
                                                <li className="params-list__item">
                                                    <span
                                                        className="params-list__item-text">{data.apartment.kitchen_area} м<sup>2</sup></span>
                                                    <span className="params-list__item-desc">Площадь кухни</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            house.deadline ?
                                                <li className="params-list__item">
                                                    <span
                                                        className="params-list__item-text">{convertToRoman(quarterDate)} кв. {year} года</span>
                                                    <span className="params-list__item-desc">Срок выдачи ключей</span>
                                                </li> : null
                                        }
                                    </ul>
                                    <hr className="decorator"/>
                                    <ul className="params-list second">
                                        {
                                            data.ceiling_height ?
                                                <li className="params-list__item">
                                                    <span
                                                        className="params-list__item-text">{data.ceiling_height}</span>
                                                    <span className="params-list__item-desc">Потолки</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.loggia ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment.loggia !== null ? `${data.apartment.loggia} м<sup>2</sup> (утеплена)` : '-'}
                                                    </span>
                                                    <span className="params-list__item-desc">
                                                        Лоджия
                                                    </span>
                                                </li> : null
                                        }
                                        {
                                            estate?.elevator_types.length > 0 ?
                                                <li className="params-list__item alone">
                                                    <span
                                                        className="params-list__item-text">{estate.elevator_types[0].name}</span>
                                                    <span className="params-list__item-desc">Лифт</span>
                                                </li> : null
                                        }
                                        {
                                            estate?.parking_types.length > 0 ?
                                                <li className="params-list__item alone">
                                                    <span
                                                        className="params-list__item-text">{estate.parking_types[0].name}</span>
                                                    <span className="params-list__item-desc">Паркинг</span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="num"></div>
                    </div>
            }
            {(house.description && house.advantages) ? (
                // <div className="item-location mixed">
                //     <div className="item-head">
                //         {
                //             house.name ?
                //                 <h3 className="item-head__title">
                //                     {house.name}
                //                 </h3> : null
                //         }
                //         <div className="head-contacts">
                //             <p className="head-contacts__agent mr-6">
                //                 {agent.last_name ? agent.last_name : null} <br/>
                //                 {agent.first_name ? agent.first_name : null}
                //             </p>
                //             <div className="head-contacits__group">
                //                 <p className="head-contacts__tel">
                //                     {phone ? phone : null}
                //                 </p>
                //                 <a className="head-contacts__mail">
                //                     {agent.email ? agent.email : null}
                //                 </a>
                //             </div>
                //         </div>
                //     </div>
                //     <div className="item-photos">
                //         <div className="item-photos__elem">
                //             <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                //                 backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                //                 backgroundSize: "cover",
                //                 backgroundRepeat: "no-repeat",
                //                 backgroundPosition: "center center"
                //             }}>
                //             </div>
                //             <div className="lists">
                //                 {
                //                     house.advantages ?
                //                         <ul className={`check_ul-pdf grid grid-cols-2 gap-[8px] text-[#4D4D4D] font-[400] leading-[16px] mt-[30px]`}>
                //                             {featuresItems?.map((item: any) => (
                //                                 item != '\r\n' ?
                //                                     <li key={item} dangerouslySetInnerHTML={{__html: item}}
                //                                     /> : null
                //                             ))}
                //                         </ul>
                //                         : null
                //                 }
                //             </div>
                //         </div>
                //         <div className="item-photos__elem">
                //             <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                //                 backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                //                 backgroundSize: "cover",
                //                 backgroundRepeat: "no-repeat",
                //                 backgroundPosition: "center center"
                //             }}>
                //             </div>
                //             <p className="main-photo__desc max-h-[200px] overflow-hidden text-justify"
                //                dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}>
                //             </p>
                //         </div>
                //     </div>
                //     <div className="num"></div>
                // </div>

                house.advantages ? <div className="item-location preferences">
                    <div className="item-head">
                        {
                            house.name ?
                                <h3 className="item-head__title">
                                    {house.name}
                                </h3> : null
                        }
                        <div className="head-contacts">
                            <p className="head-contacts__agent mr-6">
                                {agent.last_name ? agent.last_name : null} <br/>
                                {agent.first_name ? agent.first_name : null}
                            </p>
                            <div className="head-contacits__group">
                                <p className="head-contacts__tel">
                                    {phone ? phone : null}
                                </p>
                                <p className="head-contacts__mail">
                                    {agent.email ? agent.email : null}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item-photos">
                        <div className="photos">
                            <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                                backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center"
                            }}>
                            </div>
                            <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                                backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center"
                            }}>
                            </div>
                        </div>
                        {
                            house.advantages ?
                                <div className={'mt-[20px]'}>
                                    <ul className={`check_ul-pdf grid grid-cols-${Math.floor((featuresItems?.length / 4))} grid-rows-4 gap-[8px] max-md:text-[16px] text-[#4D4D4D] font-[400] leading-[26px]`}>
                                        {featuresItems?.map((item: any) => (
                                            item != '\r\n' ?
                                                <li key={item} dangerouslySetInnerHTML={{__html: item}}
                                                /> : null
                                        ))}
                                    </ul>
                                </div>
                                : null
                        }
                    </div>
                    <div className="num"></div>
                </div> : null
            ) : (
                (house.advantages && !house.description) ? (
                    house.advantages ? <div className="item-location preferences">
                        <div className="item-head">
                            {
                                house.name ?
                                    <h3 className="item-head__title">
                                        {house.name}
                                    </h3> : null
                            }
                            <div className="head-contacts">
                                <p className="head-contacts__agent mr-6">
                                    {agent.last_name ? agent.last_name : null} <br/>
                                    {agent.first_name ? agent.first_name : null}
                                </p>
                                <div className="head-contacits__group">
                                    <p className="head-contacts__tel">
                                        {phone ? phone : null}
                                    </p>
                                    <p className="head-contacts__mail">
                                        {agent.email ? agent.email : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item-photos">
                            <div className="photos">
                                <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                                <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                            </div>
                            {
                                house.advantages ?
                                    <div className={'mt-[20px]'}>
                                        <ul className={`check_ul-pdf grid grid-cols-${Math.floor((featuresItems?.length / 4))} grid-rows-4 gap-[8px] max-md:text-[16px] text-[#4D4D4D] font-[400] leading-[26px]`}>
                                            {featuresItems?.map((item: any) => (
                                                item != '\r\n' ?
                                                    <li key={item} dangerouslySetInnerHTML={{__html: item}}
                                                    /> : null
                                            ))}
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="num"></div>
                    </div> : null
                ) : (
                    // <div className="item-location">
                    //     <div className="item-head">
                    //         {
                    //             house.name ?
                    //                 <h3 className="item-head__title">
                    //                     {house.name}
                    //                 </h3> : null
                    //         }
                    //         <div className="head-contacts">
                    //             <p className="head-contacts__agent mr-6">
                    //                 {agent.last_name ? agent.last_name : null} <br/>
                    //                 {agent.first_name ? agent.first_name : null}
                    //             </p>
                    //             <div className="head-contacits__group">
                    //                 <p className="head-contacts__tel">
                    //                     {phone ? phone : null}
                    //                 </p>
                    //                 <a className="head-contacts__mail">
                    //                     {agent.email ? agent.email : null}
                    //                 </a>
                    //             </div>
                    //         </div>
                    //     </div>
                    //     {(!images[1] && !images[2]) ? (
                    //         <div className="item-photos">
                    //             <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                 <p ref={refFirstPar} className="main-photo__desc text-[14px] text-justify"
                    //                    dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}></p>
                    //             </div>
                    //         </div>
                    //     ) : (
                    //         (!images[1] || !images[2]) ? (
                    //             <div className="item-photos">
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <div className="big-photo1 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //
                    //                          }}>
                    //                     </div>
                    //                     <div className="big-photo2 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //                          }}>
                    //                     </div>
                    //                 </div>
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <p ref={refFirstPar} className="main-photo__desc text-[14px] text-justify"
                    //                        dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}>
                    //                     </p>
                    //                 </div>
                    //             </div>
                    //         ) : (
                    //             <div className="item-photos">
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <div className="big-photo1 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //
                    //                          }}>
                    //                     </div>
                    //                     <div className="big-photo2 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //                          }}>
                    //                     </div>
                    //                 </div>
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <p ref={refFirstPar} className="main-photo__desc text-[14px] text-justify"
                    //                        dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}>
                    //                     </p>
                    //                 </div>
                    //             </div>
                    //         )
                    //     )}
                    //     <div className="num"></div>
                    // </div>
                    house.advantages ? <div className="item-location preferences">
                        <div className="item-head">
                            {
                                house.name ?
                                    <h3 className="item-head__title">
                                        {house.name}
                                    </h3> : null
                            }
                            <div className="head-contacts">
                                <p className="head-contacts__agent mr-6">
                                    {agent.last_name ? agent.last_name : null} <br/>
                                    {agent.first_name ? agent.first_name : null}
                                </p>
                                <div className="head-contacits__group">
                                    <p className="head-contacts__tel">
                                        {phone ? phone : null}
                                    </p>
                                    <p className="head-contacts__mail">
                                        {agent.email ? agent.email : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item-photos">
                            <div className="photos">
                                <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                                <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                            </div>
                            {
                                house.advantages ?
                                    <div className={'mt-[20px]'}>
                                        <ul className={`check_ul-pdf grid grid-cols-${Math.floor((featuresItems?.length / 4))} grid-rows-4 gap-[8px] max-md:text-[16px] text-[#4D4D4D] font-[400] leading-[26px]`}>
                                            {featuresItems?.map((item: any) => (
                                                item != '\r\n' ?
                                                    <li key={item} dangerouslySetInnerHTML={{__html: item}}
                                                    /> : null
                                            ))}
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="num"></div>
                    </div> : null
                )
            )}
            {/*{*/}
            {/*    aboutLocationText ? <div ref={locationBlock} className="item-location-hide">*/}
            {/*        <div className="item-head">*/}
            {/*            <h3 className="item-head__title">*/}
            {/*                О локации*/}
            {/*            </h3>*/}
            {/*            <div className="head-contacts">*/}
            {/*                <p className="head-contacts__agent mr-6">*/}
            {/*                    {agent.last_name ? agent.last_name : null} <br/>*/}
            {/*                    {agent.first_name ? agent.first_name : null}*/}
            {/*                </p>*/}
            {/*                <div className="head-contacits__group">*/}
            {/*                    <p className="head-contacts__tel">*/}
            {/*                        {phone ? phone : null}*/}
            {/*                    </p>*/}
            {/*                    <a className="head-contacts__mail">*/}
            {/*                        {agent.email ? agent.email : null}*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={images[4] ? "item-content" : "item-content-flex"}>*/}
            {/*            {*/}
            {/*                images[4] ?*/}
            {/*                    <div className="big-photo1 rounded-[10px] w-full h-[250px]"*/}
            {/*                         style={{*/}
            {/*                             backgroundImage: `url(${images[4] ? images[4].links.original : null})`,*/}
            {/*                             backgroundSize: "cover",*/}
            {/*                             backgroundRepeat: "no-repeat",*/}
            {/*                             backgroundPosition: "center center"*/}
            {/*                         }}>*/}
            {/*                    </div> : null*/}
            {/*            }*/}
            {/*            {*/}
            {/*                aboutLocationText ?*/}
            {/*                    <div className="item-content__texts">*/}
            {/*                        <p ref={aboutLocationParagraph}*/}
            {/*                           className="item-content__text max-h-[500px] overflow-hidden text-justify"*/}
            {/*                           dangerouslySetInnerHTML={{__html: aboutLocationText}}>*/}
            {/*                        </p>*/}
            {/*                    </div> : null*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div className="num"></div>*/}
            {/*    </div> : null*/}
            {/*}*/}
            {/*{*/}
            {/*    endingText ? <div className="item-location-v2">*/}
            {/*        <div className="item-head">*/}
            {/*            {*/}
            {/*                house.name ?*/}
            {/*                    <h3 className="item-head__title">*/}
            {/*                        {house.name}*/}
            {/*                    </h3> : null*/}
            {/*            }*/}
            {/*            <div className="head-contacts">*/}
            {/*                <p className="head-contacts__agent mr-6">*/}
            {/*                    {agent.last_name ? agent.last_name : null} <br/>*/}
            {/*                    {agent.first_name ? agent.first_name : null}*/}
            {/*                </p>*/}
            {/*                <div className="head-contacits__group">*/}
            {/*                    <p className="head-contacts__tel">*/}
            {/*                    {phone ? phone : null}*/}
            {/*                    </p>*/}
            {/*                    <a className="head-contacts__mail">*/}
            {/*                        {agent.email ? agent.email : null}*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="item-content">*/}
            {/*            <p ref={locationRef}*/}
            {/*               className="item-content__text text-[16px] max-h-[500px] overflow-hidden text-justify"*/}
            {/*               dangerouslySetInnerHTML={{__html: endingText}}>*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*        <div className="num"></div>*/}
            {/*    </div> : null*/}
            {/*}*/}
            <div className="item-object">
                <div className="item-head">
                    <h3 className="item-head__title">
                        Об объекте
                    </h3>
                    <div className="head-contacts">
                        <p className="head-contacts__agent mr-6">
                            {agent.last_name ? agent.last_name : null} <br/>
                            {agent.first_name ? agent.first_name : null}
                        </p>
                        <div className="head-contacits__group">
                            <p className="head-contacts__tel">
                                {phone ? phone : null}
                            </p>
                            <p className="head-contacts__mail">
                                {agent.email ? agent.email : null}
                            </p>
                        </div>
                    </div>
                </div>
                {(!images[12] && !images[8]) ? (
                    <div className={"item-content item-photo-only"}>
                        <div className="item-object__photo">
                            <div className="object-params">
                                <ul className="object-list">
                                    <li className="object-list__item">
                                        <p className="object-list__item-text">
                                            {house.price_min ? formatPrice(house.price_min) : '-'} – {house.price_min ? formatPrice(house.price_max) : '-'}
                                        </p>
                                        <span className="object-list__item-desc">Цены</span>
                                    </li>
                                    <li className="object-list__item">
                                        <p className="object-list__item-text">
                                            {house.price_m2_min ? formatPrice(house.price_m2_min) : '-'} – {house.price_m2_max ? formatPrice(house.price_m2_max) : '-'}
                                        </p>
                                        <span className="object-list__item-desc">Цена за м²</span>
                                    </li>
                                </ul>
                                <ul className="object-list">
                                    {
                                        house.deadline ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {convertToRoman(quarterDate)} кв. {year} г.
                                                </p>
                                                <span className="object-list__item-desc">Срок выдачи ключей</span>
                                            </li> : null
                                    }
                                    <li className="object-list__item">
                                        <p className="object-list__item-text">
                                            {house.total_area_min ? house.total_area_min : '-'} м²
                                            – {house.total_area_max ? house.total_area_max : '-'} м²
                                        </p>
                                        <span className="object-list__item-desc">
                                        Площади квартир
                                        </span>
                                    </li>
                                    {
                                        house.type ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.type}
                                                </p>
                                                <span className="object-list__item-desc">
                                                    Тип ЖК
                                                </span>
                                            </li> : null
                                    }
                                </ul>
                            </div>
                            <div className="object-params">
                                <ul className="object-list">
                                    {
                                        builderHouse !== null && house.decorations.length ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.decorations.length + ' ' + getNoun(house.decorations.length, 'вид', 'вида', 'видов')}
                                                </p>
                                                <span className="object-list__item-desc">
                                                    Отделка
                                                </span>
                                            </li> : null
                                    }
                                    {
                                        house.total_apartments ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.total_apartments}
                                                </p>
                                                <span className="object-list__item-desc">
                                                    Квартир в продаже
                                                </span>
                                            </li> : null
                                    }
                                    {
                                        house.floor_max ?
                                            <li className="object-list__item">
                                                {house.floor_min ?
                                                    <p className="object-list__item-text">
                                                        {house.floor_min} – {house.floor_max}
                                                    </p> :
                                                    <p className="object-list__item-text">
                                                        {house.floor_max}
                                                    </p>
                                                }
                                                <span className="object-list__item-desc">
                                                    Этажность
                                                </span>
                                            </li> : null
                                    }
                                </ul>
                                <ul className="object-list">
                                    {
                                        house.ceiling_height ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.ceiling_height}
                                                </p>
                                                <span className="object-list__item-desc">Потолки</span>
                                            </li> : null
                                    }
                                    {
                                        house.balcony_area !== null && house.loggia ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.loggia ? house.loggia + 'м² (утеплена)' : null}
                                                    {house.balcony_area !== null ? `${house?.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                                </p>
                                                <span className="object-list__item-desc">Лоджия</span>
                                            </li>
                                            : null
                                    }
                                    {
                                        house.total_estates ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.total_estates}
                                                </p>
                                                <span className="object-list__item-desc">
                                                    Количество корпусов
                                                </span>
                                            </li> : null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    (!images[12] || !images[8]) ? (
                        <div className={"item-content item-content-flex"}>
                            <div className="item-object__photo w-[50%]">
                                {images[12] ?
                                    <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                         style={{
                                             backgroundImage: `url(${images[12] ? images[12].links.original : null})`,
                                             backgroundSize: "cover",
                                             backgroundRepeat: "no-repeat",
                                             backgroundPosition: "center center"
                                         }}>
                                    </div>
                                    : null}
                                {
                                    images[8] ?
                                        <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                             style={{
                                                 backgroundImage: `url(${images[8] ? images[8].links.original : null})`,
                                                 backgroundSize: "cover",
                                                 backgroundRepeat: "no-repeat",
                                                 backgroundPosition: "center center"
                                             }}>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="item-object__photo">
                                <div className="object-params">
                                    <ul className="object-list">
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_min ? formatPrice(house.price_min) : '-'} – {house.price_min ? formatPrice(house.price_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цены</span>
                                        </li>
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_m2_min ? formatPrice(house.price_m2_min) : '-'} – {house.price_m2_max ? formatPrice(house.price_m2_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цена за м²</span>
                                        </li>
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.deadline ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {convertToRoman(quarterDate)} кв. {year} г.
                                                    </p>
                                                    <span
                                                        className="object-list__item-desc">Срок выдачи ключей
                                                    </span>
                                                </li> : null
                                        }
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.total_area_min ? house.total_area_min : '-'} м²
                                                – {house.total_area_max ? house.total_area_max : '-'} м²
                                            </p>
                                            <span className="object-list__item-desc">
                                                Площади квартир
                                            </span>
                                        </li>
                                        {
                                            house.type ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.type}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Тип ЖК
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                                <div className="object-params">
                                    <ul className="object-list">
                                        {
                                            builderHouse !== null && house.decorations.length ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.decorations.length + ' ' + getNoun(house.decorations.length, 'вид', 'вида', 'видов')}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Отделка
                                                    </span>
                                                </li> : null
                                        }
                                        {
                                            house.total_apartments ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_apartments}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Квартир в продаже
                                                    </span>
                                                </li> : null

                                        }
                                        {
                                            house.floor_max ?
                                                <li className="object-list__item">
                                                    {house.floor_min ?
                                                        <p className="object-list__item-text">
                                                            {house.floor_min} – {house.floor_max}
                                                        </p> :
                                                        <p className="object-list__item-text">
                                                            {house.floor_max}
                                                        </p>
                                                    }
                                                    <span className="object-list__item-desc">
                                                        Этажность
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.ceiling_height ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.ceiling_height}
                                                    </p>
                                                    <span className="object-list__item-desc">Потолки</span>
                                                </li> : null
                                        }
                                        {
                                            house.balcony_area !== null && house.loggia ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.loggia ? house.loggia + 'м² (утеплена)' : null}
                                                        {house.balcony_area !== null ? `${house?.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                                    </p>
                                                    <span className="object-list__item-desc">Лоджия</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            house.total_estates ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_estates}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                    Количество корпусов
                                                </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={"item-content"}>
                            <div className="item-object__photo">
                                {images[12] ?
                                    <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                         style={{
                                             backgroundImage: `url(${images[12] ? images[12].links.original : null})`,
                                             backgroundSize: "cover",
                                             backgroundRepeat: "no-repeat",
                                             backgroundPosition: "center center"
                                         }}>
                                    </div>
                                    : null
                                }
                                <div className="object-params">
                                    <ul className="object-list">
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_min ? formatPrice(house.price_min) : '-'} – {house.price_min ? formatPrice(house.price_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цены</span>
                                        </li>
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_m2_min ? formatPrice(house.price_m2_min) : '-'} – {house.price_m2_max ? formatPrice(house.price_m2_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цена за м²</span>
                                        </li>
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.deadline ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {convertToRoman(quarterDate)} кв. {year} г.
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Срок выдачи ключей
                                                    </span>
                                                </li> : null
                                        }
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.total_area_min ? house.total_area_min : '-'} м²
                                                – {house.total_area_max ? house.total_area_max : '-'} м²
                                            </p>
                                            <span className="object-list__item-desc">
                                                Площади квартир
                                            </span>
                                        </li>
                                        {
                                            house.type ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.type}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Тип ЖК
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="item-object__photo">
                                {
                                    images[8] ?
                                        <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                             style={{
                                                 backgroundImage: `url(${images[8] ? images[8].links.original : null})`,
                                                 backgroundSize: "cover",
                                                 backgroundRepeat: "no-repeat",
                                                 backgroundPosition: "center center"
                                             }}>
                                        </div>
                                        : null
                                }
                                <div className="object-params">
                                    <ul className="object-list">
                                        {
                                            builderHouse !== null && house.decorations.length ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.decorations.length + ' ' + getNoun(house.decorations.length, 'вид', 'вида', 'видов')}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Отделка
                                                    </span>
                                                </li> : null
                                        }
                                        {
                                            house.total_apartments ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_apartments}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Квартир в продаже
                                                    </span>
                                                </li> : null

                                        }
                                        {
                                            house.floor_max ?
                                                <li className="object-list__item">
                                                    {house.floor_min ?
                                                        <p className="object-list__item-text">
                                                            {house.floor_min} – {house.floor_max}
                                                        </p> :
                                                        <p className="object-list__item-text">
                                                            {house.floor_max}
                                                        </p>
                                                    }
                                                    <span className="object-list__item-desc">
                                                        Этажность
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.ceiling_height ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.ceiling_height}
                                                    </p>
                                                    <span className="object-list__item-desc">Потолки</span>
                                                </li> : null
                                        }
                                        {
                                            house.balcony_area !== null && house.loggia ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.loggia ? house.loggia + 'м² (утеплена)' : null}
                                                        {house.balcony_area !== null ? `${house?.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                                    </p>
                                                    <span className="object-list__item-desc">Лоджия</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            house.total_estates ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_estates}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                    Количество корпусов
                                                </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                )}
                <div className="num"></div>
            </div>
            <div className="item-infrastructure">
                <div className={'map-pdf'} style={{
                    backgroundImage: `url(${dataStatic})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center"
                }}>
                    <div className={'map-pdf-icons'} style={{
                        backgroundImage: `url(${dataReadyIcons})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        position: 'absolute',
                    }}>
                    </div>
                    {
                        <div className={'icon-place-container'}>
                            <img src={defaultIcon.iconImageHref} alt={'place-icon'}/>
                        </div>
                    }
                </div>
                <div className="map-content">
                    <div className="item-head__title map-title mb-[40px]">
                        <h2 className="map-title__content mb-7">
                            Инфраструктура
                        </h2>
                        <div className="map-title__address mb-[20px]">
                            {house.name ? house.name + ',' + ' ' : null}
                            {house.city.name ? house.city.name + ',' + ' ' : null}
                            {house.address ? house.address : null}
                        </div>
                        <div className={'flex flex-col gap-[8px]'}>
                            <div className={'flex flex-wrap gap-[10px]'}>
                                {house.hc_metros?.map((hc_metro: any, _idx: number) => (
                                    <div key={hc_metro.id} className={'flex flex-row gap-[8px] items-center'}>
                                        {
                                            hc_metro.metro.color ?
                                                <span style={{background: hc_metro.metro.color}}
                                                      className={`block w-[10px] h-[10px] rounded-[50%]`}/>
                                                : null
                                        }
                                        <span
                                            className={'text-[16px] text-[#4D4D4D] leading-[24px] font-normal'}>{hc_metro.metro.name}</span>
                                        <span
                                            className={'flex flex-row gap-[4px] items-center text-[14px] text-[#A6A6A6] font-[400] leading-[18px]'}>
                                            {
                                                hc_metro.metro_infos[_idx] ?
                                                    <img
                                                        src={`./../../icons/metros/${hc_metro.metro_infos[_idx]?.metro_way_alias}.svg`}
                                                        alt="metro-info"/> : null
                                            }
                                            {
                                                hc_metro.metro_infos[0] ?
                                                    <span>{hc_metro.metro_infos[0].minutes} мин.</span> : null
                                            }
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="map-prefer mt-[65px]">
                        <ul className={'grid grid-cols-2 gap-[50px]'}>
                            {
                                house.infrastructures?.map((el: any) =>
                                    <li key={el.id} className={'flex flex-row items-center'}>
                                        <img className={'mr-[12px]'}
                                             src={require(`../../svg/infrastructure/${el.icon}.svg`)}
                                             alt={`${el.icon}-infrastructures`}/>
                                        <p>{el.name}</p>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="num"></div>
            </div>
            {
                builderHC !== null && house.decorations.length > 0 ?
                    <div className="item-trim">
                        <div className="item-head">
                            <h3 className={'item-head__title'}>Варианты отделки</h3>
                        </div>
                        <div className="trim-content">
                            {
                                house.decorations?.map((el: any) => (
                                    <div key={el.id} className="trim-item">
                                        <div className="trim-photo rounded-[10px] h-[250px] w-full"
                                             style={{
                                                 backgroundImage: `url(${el.images ? el.images[0].links.preview : null})`,
                                                 backgroundSize: "cover",
                                                 backgroundRepeat: "no-repeat",
                                                 backgroundPosition: "center center",
                                             }}>
                                        </div>
                                        <div className="trim-item__text">
                                            <div className="trim-item__title">{el.type ? el.type : null}</div>
                                            <ul className="trim-list">
                                                <li className="trim-list__elem text-justify text-[14px]"
                                                    dangerouslySetInnerHTML={{__html: el.description.split('\r')[0]}}>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="num"></div>
                    </div> : null
            }
            {
                builderHC !== null && house.construction_phases.length > 0 &&
                <div className="item-process">
                    <div className="item-head__title process-head mb-6">
                        Ход строительства
                    </div>
                    <div className="process-content">
                        {
                            house.construction_phases?.map((el: any) => (
                                <div key={el.id} className="process-item">
                                    <div className="process-photo rounded-[10px] h-[250px] w-full" style={{
                                        backgroundImage: `url(${el.images[0].links.large})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                    }}>
                                    </div>
                                    <div className="process-item__text">
                                        <div
                                            className="process-item__title text-black text-[26px] font-bold">{el.date.split('.')[2]}</div>
                                        <div className="process-item__desc">
                                            {new Date(
                                                el.date.split('.').reverse()
                                            ).toLocaleString('default', {month: 'long'})}
                                        </div>
                                        {
                                            el.comment !== 'null' && el.comment ?
                                                <p className={'process-item__comment text-justify'}>
                                                    {el.comment}
                                                </p> : null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="num"></div>
                </div>
            }
            <div className="item-contacts v2">
                <h2 className="item-head__title head-contacts">
                    Контакты
                </h2>
                <div
                    className={!agent.last_name && !agent.first_name ? "contacts-content h-full justify-center absolute w-full top-0 left-0" : 'contacts-content'}>
                    <div className="agent">
                        {
                            agent.image ?
                                <div className="agent-photo rounded-[191.5px]"
                                     style={{
                                         backgroundImage: `url(${agent.image.links.preview})`,
                                         backgroundSize: "cover",
                                         backgroundRepeat: "no-repeat",
                                         backgroundPosition: "center center"
                                     }}>
                                </div> : null
                        }
                        <span className="agent-name">
                                    {agent.last_name ? agent.last_name : null} {agent.first_name ? agent.first_name : null}
                                </span>
                    </div>
                    <div className="card">
                        {
                            house.developer ?
                                <div className="card-title">
                                    <span className="card-title__name">
                                    {house.developer.name}
                                </span>
                                </div> : null
                        }
                        {
                            house.developer ? <div className="card-decorator"></div> : null
                        }

                        <div className="card-contacts">
                            {
                                !agent.last_name && !agent.first_name ?
                                    <div className="card-name mb-2">Симонова Марина</div> : null
                            }
                            <div className="card-tel mb-3">
                                <div className="card-tel__tel">{phone ? phone : null}</div>
                                <div className="card-tel__mail">{agent.email ? agent.email : null}</div>
                            </div>
                            <div className="card-social">
                                {
                                    agent.telegram &&
                                    <div className="card-social__item">
                                        <img src="/icons/telegram.svg" alt="tg-icon"/>
                                        <div className="card-socail__item-name">Telegram</div>
                                        <div className="card-socail__item-alt">{agent.telegram}</div>
                                    </div>
                                }
                                {
                                    agent.whatsapp &&
                                    <div className="card-social__item">
                                        <img src="/icons/whatsapp.svg" alt="wa-icon"/>
                                        <div className="card-socail__item-name">WhatsApp</div>
                                        <div
                                            className="card-socail__item-alt">{formatInternationalPhoneNumber(agent.whatsapp, 'RU')}</div>
                                    </div>
                                }
                                {
                                    agent.viber &&
                                    <div className="card-social__item">
                                        <img src="/icons/viber.svg" alt="viber-icon"/>
                                        <div className="card-socail__item-name">Viber</div>
                                        <div
                                            className="card-socail__item-alt">{formatInternationalPhoneNumber(agent.viber, 'RU')}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Pdf;