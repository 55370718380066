import React, {useEffect, useRef, useState} from "react";
import Pdf from "./pdf";
import {Link } from "react-router-dom";
import  {jsPDF} from 'jspdf'
import html2canvas from "html2canvas";
import LoaderPDF from "../ui/loaderPDF";
// @ts-ignore
import scss from './loadingPagePdf.module.scss'
import imagePDFReady from './../../svg/pdf/pdf-loading-image.svg'
import LoaderPDFNotify from "../ui/loaderPDFnotify";

const PdfLoadingPage = ({data}) => {
    const [pdf,setPDF] = useState<any>()
    const [totalPages,setTotalPages] = useState<number>(0)
    const [pdfReady, setPdfReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [error,setError] = useState(false)
    const componentRef = useRef(null);
    const [startLoading, setStartLoading] = useState(false);

    const house = data.housing_complex || data.apartment.housing_complex;

    const generatePdf = async () => {
        setLoading(true);
        setLoadingProgress(10);

        try {
            if (componentRef.current) {
                const pdf = new jsPDF('landscape', 'pt', 'a4');
                pdf.setFillColor(255, 255, 255);
                pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
                const canvas = await html2canvas(componentRef.current, { useCORS: true, backgroundColor: '#FFFFFF' });
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const pageHeight = pdf.internal.pageSize.getHeight();
                let heightLeft = imgHeight;
                let position = 0;

                const totalPages = Math.ceil(imgHeight / pageHeight);
                setTotalPages(totalPages / 2);

                for (let pageNum = 0; pageNum < totalPages; pageNum++) {

                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    if (heightLeft >= 0) {
                        position -= pageHeight;
                        pdf.addPage();
                    }
                }
                setLoadingProgress(100);
                setPDF(pdf);
            }
        } catch (error) {
            console.error('Ошибка при создании PDF:', error);
            setError(true);
        }
    };

    const downloadPdf = async () => {
        await generatePdf();
    };

    useEffect(() => {
        downloadPdf()
    }, []);

    useEffect(() => {
        if(loadingProgress === 100){
            setTimeout(() => {
                setLoading(false);
                setPdfReady(true)
                setStartLoading(true)
            }, totalPages * 1000);
        }
    }, [loadingProgress]);

    return (
        <>
            {
                !pdfReady && loading && !error &&
                <div className={scss.containerLoading}>
                    <LoaderPDF loadingProgress={loadingProgress} totalPages={totalPages}/>
                    <div className={scss.loaderPDFDesc}>
                        <p className={scss.loaderPDFdesc_title}>Идёт процесс формирования PDF файла</p>
                        <p className={scss.loaderPDFdesc_subTitle}>Скоро начнётся загрузка файла презентации</p>
                    </div>
                    <Link className={scss.loaderPDFBtn} to={`/${window.location.search}`}>
                        Вернуться назад
                    </Link>
                </div>
            }
            {
                error &&
                <div className={scss.containerLoading}>
                    <div className={scss.loaderPDFDesc}>
                        <p className={scss.loaderPDFdesc_title}>Произошла ошибка при загрузке документа</p>
                    </div>
                    <Link className={scss.loaderPDFBtn} to={`/${window.location.search}`}>
                        Вернуться назад
                    </Link>
                </div>
            }
            {
                pdfReady && !error &&
                <div className={scss.containerLoading}>
                    <div style={{
                        backgroundImage: `url(${imagePDFReady})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        width: "284.99px",
                        height: "261.54px",
                        marginBottom: "67px"
                    }}></div>
                    <div className={scss.loaderPDFDesc}>
                        <p className={scss.loaderPDFdesc_title}>Формирование PDF файла завершено!</p>
                        <p className={scss.loaderPDFdesc_subTitle}>Скачать файл презентации можно по кнопке ниже</p>
                    </div>
                    <div className={scss.loaderButtons}>
                        <Link className={scss.loaderPDFBtn_prev} to={`/${window.location.search}`}>
                            Вернуться назад
                        </Link>
                        <button className={scss.loaderPDFBtn_load}
                        onClick={() => {
                            pdf.save(`${house.name}.pdf`);
                        }}>
                            Скачать файл
                        </button>
                    </div>
                </div>
            }
            {
                !error &&
                <Pdf ref={componentRef} data={data} status={'pdf'}/>
            }
            {
                startLoading &&
                    <LoaderPDFNotify text={'Готово! Файл презентации загружен'} status={true} />
            }
            {
                error &&
                    <LoaderPDFNotify
                        text={'Что-то пошло не так! Загрузка приостановлена'}
                        status={false}
                    />
            }
        </>
    );
};

export default PdfLoadingPage;